import { CardBannerTop } from "../../components/card-banner-top";
import { useTranslateDocument } from "../../hooks/use-translate-document";
import * as S from "./register-styles";
import { RegisterTranslate } from "./register-translate";

import DownloadAppStoreEN from "../../assets/images/png/download-app-store-en.png";
import DownloadAppStorePT from "../../assets/images/png/download-app-store-pt.png";
import DownloadGooglePlayEN from "../../assets/images/png/download-google-play-en.webp";
import DownloadGooglePlayPT from "../../assets/images/png/download-goole-play-pt.png";
import { useTranslate } from "../../hooks/use-translate";

export const RegisterPage = () => {
  const { language } = useTranslate();
  const { translate } = useTranslateDocument(RegisterTranslate);
  return (
    <S.Container>
      <CardBannerTop subtitle={translate("title")}>
        <S.WrapperInfoAction>
          <S.InfoAction>{translate('info_action')}</S.InfoAction>
          <S.ImageApplication/>
        </S.WrapperInfoAction>
      </CardBannerTop>

      {/* <S.CardBannerStore>
        <S.ContentCardBannerStore>
          <S.Subtitle>{translate("subtitle")}</S.Subtitle>
          <S.WrapperButtonStore>
            <S.ButtonStore
              href="itms-apps://?action=discover&referrer=app-store&itscg=10000&itsct=app-appstore-nav-200917"
              target="_blank"
            >
              <S.ImageStore
                src={language == "PT" ? DownloadAppStorePT : DownloadAppStoreEN}
              />
            </S.ButtonStore>
            <S.ButtonStore href="https://play.google.com/store/apps/details?id=com.SabiaFarmerApp" target="_blank">
              <S.ImageStore
                src={
                  language == "PT" ? DownloadGooglePlayPT : DownloadGooglePlayEN
                }
              />
            </S.ButtonStore>
          </S.WrapperButtonStore>
        </S.ContentCardBannerStore>
      </S.CardBannerStore> */}
    </S.Container>
  );
};
